export const baseViewFormConfig = {
  formItems: [
    {
      field: 'key',
      type: 'input',
      label: 'terminal.batch-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'value',
      type: 'input',
      label: 'terminal.batch-id',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'order',
      type: 'input',
      label: 'category.order',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500,
        disabled: true
      }
    }
  ],
  validateRules: {}
}
