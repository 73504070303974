import { basicRequiredRule } from '@/template/rule'
import Validator from '@/validators'

export const baseNewFormConfig = {
  formItems: [
    {
      field: 'key',
      type: 'input',
      label: 'terminal.batch-name',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'value',
      type: 'input',
      label: 'terminal.batch-id',
      otherOptions: {
        maxlength: 255
      }
    },
    {
      field: 'order',
      type: 'input',
      label: 'category.order',
      otherOptions: {
        maxlength: 3
      }
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500
      }
    }
  ],

  validateRules: {
    key: [basicRequiredRule],
    value: [basicRequiredRule],
    order: [
      { validator: Validator.integer, message: 'validator.must-be-integer' }
    ]
  }
}
